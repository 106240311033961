import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import '../assets/css/SEOChecker.css';

const SEODonutChart = ({ score }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        let chartStatus = Chart.getChart(ctx);
        if (chartStatus !== undefined) {
            chartStatus.destroy();
        }

        const color = score >= 80 ? '#1DBAB0' : score >= 60 ? 'yellow' : score >= 50 ?'orange' : 'red';

        new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [
                    {
                        data: [score, 100 - score],
                        backgroundColor: [color, '#e0e0e0'],
                        borderWidth: 0,
                    },
                ],
            },
            options: {
                cutout: '80%',
                plugins: {
                    tooltip: { enabled: false },
                    legend: { display: false },
                    datalabels: {
                        display: true,
                        formatter: (value, context) => {
                            return context.chart.data.labels[context.dataIndex];
                        },
                    },
                },
            },
        });
    }, [score]);

    return (
        <div className="seo-donut-chart-container">
            <canvas ref={chartRef} width="200" height="200"></canvas>
            <div className="seo-donut-chart-score display-grid">
                {score}%

            <br/>
                {score >= 80 ? <span style={{fontSize: "1rem", textAlign: "center"}}>Très bon</span> :
                    score >= 60 ? <span style={{fontSize: "1rem", textAlign: "center"}}>Bon</span> :
                    score >= 50 ? <span style={{fontSize: "1rem", textAlign: "center"}}>Moyen</span> :
                        score < 50 ? <span style={{fontSize: "1rem", textAlign: "center"}}>Mauvais</span> :
                            null}


            </div>
        </div>
    );
};

export default SEODonutChart;