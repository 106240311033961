import React from 'react';

const MainContent = ({ isVisible, children }) => {
    const mainContainerStyle = {
        marginLeft: isVisible ? '400px' : '0', // Set margin-left based on visibility
        borderTopLeftRadius: '15px',
        // Add any other styles you need for the main content container
    };

    return (
        <div className="main-container-composant" style={mainContainerStyle}>
            {children}
        </div>
    );
};

export default MainContent;
