import React from 'react';

const Loading = () => {
    return (
        <div>
            <img className={'loading-img'} src={'./assets/loading.webp'}  alt={'Loading...'}/>
        </div>
    );
};

export default Loading;