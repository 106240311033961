import React, {useState} from 'react';
import WIP from "../components/WIP";
import axios from "axios";

const DnsPropagation = () => {
    const [domain, setDomain] = useState('');
    const [ipv4Results, setIpv4Results] = useState([]);
    const [ipv6Results, setIpv6Results] = useState([]);

    const checkDnsPropagation = async () => {
        try {
            const responseV4 = await axios.get(`https://api.dnschecker.org/resolve?name=${domain}&type=A`);
            setIpv4Results(responseV4.data.answer.map(answer => answer.data));
        } catch (error) {
            console.error('Error fetching IPv4 addresses:', error);
            setIpv4Results([]);
        }

        try {
            const responseV6 = await axios.get(`https://api.dnschecker.org/resolve?name=${domain}&type=AAAA`);
            setIpv6Results(responseV6.data.answer.map(answer => answer.data));
        } catch (error) {
            console.error('Error fetching IPv6 addresses:', error);
            setIpv6Results([]);
        }
    };

    return (
        <div>
            <input type="text" placeholder="Enter domain" value={domain} onChange={e => setDomain(e.target.value)} />
            <button onClick={checkDnsPropagation}>Check DNS Propagation</button>
            <div>
                <h3>IPv4 Addresses:</h3>
                <ul>
                    {ipv4Results.map((ipv4, index) => (
                        <li key={index}>{ipv4}</li>
                    ))}
                </ul>
            </div>
            <div>
                <h3>IPv6 Addresses:</h3>
                <ul>
                    {ipv6Results.map((ipv6, index) => (
                        <li key={index}>{ipv6}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DnsPropagation;