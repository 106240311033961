import React, { useState } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import {API_BASE_URL} from "../config";
import logo from '../assets/logo-sd.webp'

const Register = () => {
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('')
    const [registerMessage, setRegisterMessage] = useState('');
    const roles = ['Administrateur', 'Editeur', 'Consultant'];
    const [password, setPassword] = useState('');
    const [generateStrong, setGenerateStrong] = useState(false);

    const generateStrongPassword = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const specialChars = '!@#$%^&*()-_=+[{]}|;:,<.>/?';
        let strongPassword = '';

        for (let i = 0; i < 9; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            strongPassword += chars[randomIndex];
        }

        const randomSpecialChar = specialChars[Math.floor(Math.random() * specialChars.length)];
        strongPassword += randomSpecialChar;

        // Shuffle the password characters to mix things up
        strongPassword = shuffleString(strongPassword);

        setPassword(strongPassword);
    };

    // Function to shuffle a string (Fisher-Yates algorithm)
    const shuffleString = (str) => {
        const array = str.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    };

    const handleRegister = (e) => {
        e.preventDefault();

        axios.post(`${API_BASE_URL}/register`, { user, email, password, role })
            .then(response => {
                if (response.data.status === 'ok') {
                    // Successful login
                    // Perform actions such as setting user session, redirecting, etc.
                    setRegisterMessage('Utilisateur enregistré avec succès');

                } else {
                    // Wrong credentials
                    setRegisterMessage('Une erreur est survenue.');
                }
            })
            .catch(error => {
                // Handle network errors
                console.error('Error:', error);
                setRegisterMessage('An error occurred. Please try again later.');
            });
    };

    return (
        <div>
            <div className="register-container">
                <form className="register-form" onSubmit={handleRegister}>
                    <div className="image-container">
                        <img src={logo} alt="Solution Digitale Logo" className="logo-image"/>
                    </div>
                    <h2 className="title">Création d'un utilisateur</h2>
                    <div className="input-container">
                        <div className="input-icon">
                            <i className="fas fa-user icon"></i>
                        </div>
                        <input className="input" type="text" placeholder="Prénom et nom" required
                               autoFocus={true}
                               value={user} onChange={(e) => setUser(e.target.value)}/>
                    </div>
                    <div className="input-container">
                        <div className="input-icon">
                            <i className="fas fa-envelope icon"></i>
                        </div>
                        <input className="input" type="email" placeholder="Email" required
                               value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="input-container">
                        <div className="input-icon">
                            <i className="fas fa-lock icon"></i>
                        </div>
                        <input
                            className="input"
                            type="text"
                            placeholder="Mot de passe"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="btn-generate" onClick={generateStrongPassword}><i
                            className="fa-solid fa-arrows-rotate"></i></span>
                    </div>
                    <div className="input-container custom-select">
                        <div className="input-icon">
                            <i className="fa-solid fa-user-lock icon"></i>
                        </div>
                        {/* Dropdown for roles */}
                        <select
                            className="input"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        >
                            <option value="" disabled hidden>Choisir un rôle</option>
                            {roles.map((roleOption) => (
                                <option key={roleOption} value={roleOption}>{roleOption}</option>
                            ))}
                        </select>
                    </div>
                    {/* Display login error message */}
                    {registerMessage && <p className="error-message">{registerMessage}</p>}
                    <button className="btn-login" type="submit">Enregistrer un utilisateur</button>

                </form>
            </div>
        </div>
    );
};

export default Register;
