import React, {useState, useEffect} from 'react';
import axios from 'axios';
import SearchBar from "../components/SearchBar";
import Table from "../components/Table";
import {API_BASE_URL} from "../config"; // Update the path to config.js as needed

const PositionsDeleted = () => {
    const [published, setPublished] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [sortBy, setSortBy] = useState({column: null, ascending: true});

    const columnMapping = {
        "Progression": "progression",
        'N° Bexio commande': 'order_id',
        "Titre de la commande": "title",
        "Client": "client",
        "Date de création du projet": "date",
        // Add more mappings as needed
    };

    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({column: actualColumn, ascending});
    };

    const handleAction = (action, id) => {

        // On edit/voit la position
        if (action === 'edit') {

            const url = `/positions/${id}`;
            window.open(url);
        }

    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_deleted_positions`, {state: 'deleted'})
            .then(res => {

                if (res.data.status === 'ok') {
                    setPublished(res.data.datas);
                    setTotalPositions(res.data.datas)
                } else {
                    console.error('Erreur :', res.data.error);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // Ensure published is always an array
    const publishedArray = Array.isArray(published) ? published : [];

    const customSort = (a, b) => {
        switch (sortBy.column) {
            case 'progression':
                return sortBy.ascending ? a.progress - b.progress : b.progress - a.progress;
            case 'order_id':
                return sortBy.ascending ? a.order_id - b.order_id : b.order_id - a.order_id;
            case 'title':
                return sortBy.ascending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
            case 'client':
                return sortBy.ascending ? a.name_1.localeCompare(b.name_1) : b.name_1.localeCompare(a.name_1);
            case 'order_start_date':
                return sortBy.ascending ? a.order_start_date.localeCompare(b.order_start_date) : b.order_start_date.localeCompare(a.order_start_date);
            case 'date_finish':
                return sortBy.ascending ? a.date_finish.localeCompare(b.date_finish) : b.date_finish.localeCompare(a.date_finish);
            // Add cases for other columns as needed
            default:
                return 0; // Default case
        }
    };
    const sortedArray = [...publishedArray].sort(customSort);

    return (
        <div>
            <SearchBar totalPositions={totalPositions} setPublished={setPublished}
                       totalPositionsLength={publishedArray} positionLabel={'Projets supprimés'}
                       searchLabel={'projets supprimés trouvés'}/>
            <Table
                data={sortedArray}
                handleSort={handleSort}
                sortBy={sortBy}
                showActions={true}
                handleAction={handleAction}
                headers={["N° Bexio commande", "Titre de la commande", "Client", "Supprimé le", "Actions"]}
                headersData={['order_id', 'title', 'name_1', 'deletion_date']}
            />
        </div>

    );
};

export default PositionsDeleted;
