import React from 'react';
import '../assets/css/ReportingCard.css'; // Ensure to import the CSS file

const ReportingCard = ({header, data, icon}) => {
    return (
        <div className="reporting-card">
            <div className="resume display-flex align-center">
                {icon && <i className={`fa-solid ${icon} m-10 reporting-icon`} style={{color: "var(--secondary)"}} />}

                <div className="display-flex column text-container">
                    {data && <h3 className="m-5 text-secondary title-label-reporting data">{data}</h3>}
                    {header && <h4 className="m-5 text-primary header">{header}</h4>}
                </div>
            </div>
        </div>
    );
};

export default ReportingCard;
