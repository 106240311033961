import React, { useState } from 'react';

const SeoResult = ({ data }) => {
    const [copied, setCopied] = useState(false);

    const addToClipboard = () => {
        navigator.clipboard.writeText(data).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Revert back after 2 seconds
        }).catch(err => {
            console.error('Error copying text to clipboard', err);
        });
    };

    return (
        <div className={'mt-1'}>
            <div className={'display-grid container-seo'}>
                <input className={'input-time m-5'} value={data} readOnly />
                <span onClick={addToClipboard} className={'btn-generate btn-copy'}>
                    <i className={copied ? 'fa-solid fa-check' : 'fa-solid fa-copy'} />
                </span>
            </div>
        </div>
    );
};

export default SeoResult;
