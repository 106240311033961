import React, {Fragment, useEffect, useState} from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";
import ToggleContent from "../components/ToggleContent";
import ExportSaveButtons from "../components/ExportSaveButtons";
import OpenPositions from "../components/OpenPositions";
import {API_BASE_URL} from "../config";
import '../assets/css/Position.css'
import ReactDOM from "react-dom";

const PositionDetail = () => {
    const {order_id} = useParams(); // Extract order_id from URL params
    const [position, setPosition] = useState(null);
    const [tasks, setTasks] = useState('')
    const [loading, setLoading] = useState(true);
    const [positionNote, setPositionNote] = useState('');
    const [isInformationBlockVisible, setIsInformationBlockVisible] = useState(false);
    const user_name = localStorage.getItem('user_name');

    useEffect(() => {

        axios.post(`${API_BASE_URL}/get_positions/${order_id}`)
            .then(response => {
                console.log(response)
                const {data} = response;
                if (data.status === 'ok') {

                    console.log(data)
                    setPosition(data.datas);
                    setPositionNote(data.datas[0].production_note)
                } else {
                    console.error('Error fetching position details:', data.message);
                }
                setLoading(false);
            })

    }, [order_id]); // Fetch data when order_id changes

    useEffect(() => {
        const currentUrl = window.location.href;
        const position_id = currentUrl.split('/positions/')[1]; // Extract position_id from the URL

        axios.post(`${API_BASE_URL}/get_all_tasks`, {positionId: position_id})
            .then(res => {
                setTasks(res.data.datas)
            })
    }, [order_id])

    const fetchTasks = () => {
        const currentUrl = window.location.href;
        const position_id = currentUrl.split('/positions/')[1]; // Extract position_id from the URL

        axios.post(`${API_BASE_URL}/get_all_tasks`, {positionId: position_id})
            .then(response => {
                setTasks(response.data.datas);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
                setLoading(false);
            });
    };

    const handleCommentSection = (value) => {
        // Update the local state with the new value
        setPositionNote(value);
        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        // Assuming you have an API endpoint to update the position note
        axios.post(`${API_BASE_URL}/update_position_note`, {
            note: value, id: positionId
        }).then(response => {
            const {data} = response;
            if (data.status === 'ok') {
            } else {
                console.error('Error updating position note:', data.message);
            }
        }).catch(error => {
            console.error('Error updating position note:', error);
        });
    };
    const deleteTasks = (position) => {
        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        const res = window.confirm("Voulez-vous vraiment supprimer cette position ?");

        if (res) {
            if (!positionId || isNaN(positionId)) {
                throw new Error('Invalid position ID in URL');
            }

            axios.post(`${API_BASE_URL}/delete_position_tasks`, {id: positionId})
                .then(response => {
                    setTasks(null);
                    fetchTasks();
                })
                .catch(error => {
                    console.error('Error fetching default tasks:', error);
                });
        }
    }

    const finishPosition = () => {
        const currentUrl = window.location.href;
        const id = currentUrl.split('/').pop();

        axios.post(`${API_BASE_URL}/finish_position`, {id})
            .then(response => {
                fetchTasks();
            })
            .catch(error => {
                console.error('Error fetching default tasks:', error);
            });
    }

    const createDefaultTasks = (position) => {
        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        if (!positionId || isNaN(positionId)) {
            throw new Error('Invalid position ID in URL');
        }

        const id = position.article_id;
        axios.post(`${API_BASE_URL}/get_default_tasks`, {id})
            .then(response => {
                console.log(position)
                // TODO endpoint get_bexio_user_id
                const defaultTasksString = response.data[0].default_tasks;
                const tasksArray = defaultTasksString.split(',');

                axios.post(`${API_BASE_URL}/set_default_tasks`, {tasks: tasksArray, positionId})
                    .then(() => {
                        // Fetch updated tasks after creating default tasks
                        fetchTasks();
                    })
                    .catch(error => {
                        console.error('Error creating tasks:', error);
                    });
            })
            .catch(error => {
                console.error('Error fetching default tasks:', error);
            });
    };

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (!position) {
        return <div>Aucune position trouvée</div>;
    }

    const toggleVisibility = () => {
        setIsInformationBlockVisible(!isInformationBlockVisible);
    };

    return (<Fragment>
        {position.map((position) => (<Fragment>

                {position.is_archived === 1 && <div>
                    <span className={"archived-div"}><p>Attention, ce projet est archivé</p></span></div>}
                <div key={position.id}>
                    <div>
                        <h1 dangerouslySetInnerHTML={{__html: position.name_1}}/>

                        <span className={"text-primary position-id-text"}>{position.order_id} - </span>
                        <span className={"text-primary position-id-text"}
                              dangerouslySetInnerHTML={{__html: position.title}}/><br/>
                        <hr/>
                        <div className={"position-container"}>

                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                {/* Premier élément */}

                                <div style={{padding: "15px 0px 15px 0"}}>

                                    {/*<button className={"button-action"}
                                        style={{padding: "10px 15px", cursor: "pointer", margin: 0}}
                                        onClick={handleDropdownToggle}>
                                    <i className="fa-solid fa-user"/> {labelAddUser}
                                </button>*/}
                                    <button className={"button-action end-project"}
                                            onClick={() => finishPosition(position)}
                                            style={{padding: "10px 15px", cursor: "pointer", marginLeft:0}}>
                                        <i className="fa-solid fa-check"
                                           style={{marginRight: "5px"}}></i>Mettre le projet à 100%
                                    </button>
                                    <button className={"button-action create-task"}
                                            style={{padding: "10px 15px", cursor: "pointer"}}
                                            onClick={() => createDefaultTasks(position)}>
                                        <i className="fa-regular fa-plus"
                                           style={{marginRight: "5px"}}></i>Créer les tâches par défaut
                                    </button>

                                    {localStorage.user_role === 'Administrateur' && (<button
                                        className={"button-action delete"}
                                        onClick={() => deleteTasks(position)}
                                        style={{padding: "10px 15px", cursor: "pointer"}}
                                    >
                                        <i className="fa-solid fa-trash" style={{marginRight: "5px"}}></i>
                                        Supprimer toutes les tâches
                                    </button>)}
                                </div>

                                {/* Deuxième élément */}
                                <ExportSaveButtons/>
                            </div>
                            <div className={"flex"}>
                                <div className={"position-container left container-positions"}>
                                    <div className={"position-component"}>
                                        <h3>
                                            <i className="fa-solid fa-house" style={{marginRight: "10px"}}></i>
                                            Informations du client
                                        </h3>
                                        <span className={'icon-info-client'}>
                                            <i className="fa-solid fa-house" style={{marginRight: "10px"}}/>
                                        </span>
                                        <span>{position.name_1}</span>
                                        <p style={{marginLeft: "40px"}}>{position.name_2}</p>
                                        <p style={{marginLeft: "40px"}}>{position.address}</p>
                                        <p style={{marginLeft: "40px"}}>{position.postcode} {position.city}</p>
                                        <p>
                                            <a style={{textDecoration: "none"}}
                                               href={"tel:" + position.phone_fixed}>
                                            <span className={'icon-info-client'}>
                                                <i className="fa-solid fa-phone" style={{marginRight: "10px"}}/>
                                            </span>
                                                {position.phone_fixed}
                                            </a>
                                        </p>
                                        <p>
                                            <a style={{textDecoration: "none"}} href={"mailto:" + position.mail}>
                                            <span className={'icon-info-client'}>
                                                <i className="fa-solid fa-envelope" style={{marginRight: "10px"}}/>
                                            </span>
                                                <span>{position.mail}</span></a>
                                        </p>
                                        <p>
                                            <a style={{textDecoration: "none"}} target={"_blank"}
                                               href={"https://office.bexio.com/index.php/kb_order/show/id/" + position.order_id}
                                               rel="noreferrer">

                                            <span className={'icon-info-client'}>
                                            <i className="fa-solid fa-link" style={{marginRight: "10px"}}/>
                                        </span>
                                                <span>

                                            Contrat Bexio
                                            </span>
                                            </a>
                                        </p>
                                        <p>
                                            <span className={'icon-info-client'}>
                            <i className="fa-solid fa-industry"/>                                            </span>
                                            <span>{position.branch_name}</span>
                                        </p>
                                        {position.url !== null && <p>
                                            <a style={{textDecoration: "none"}} target={"_blank"}
                                               href={position.url}
                                               rel="noreferrer">

                                            <span className={'icon-info-client'}>
                                            <i className="fa-solid fa-link" style={{marginRight: "10px"}}/>
                                        </span>
                                                <span>

                                            {position.url}
                                            </span>
                                            </a>
                                        </p>

                                        }

                                        <p>
                                        <span style={{textDecoration: "none"}}>
                                            <span className={'icon-info-client'}>
                                            <i className="fa-solid fa-user"
                                               style={{
                                                   marginRight: "10px"
                                               }}/>
                                            </span>
                                            <span>{position.contact_firstname} {position.contact_lastname}</span>
                                        </span>
                                        </p>
                                    </div>
                                    <div className={"position-component"}>
                                        <h3 style={{marginTop: 0}}>
                                            <i className="fa-solid fa-circle-info"
                                               style={{marginRight: "10px"}}></i>
                                            Informations générales
                                        </h3>

                                        {(user_name === 'Lila') ? (<Fragment>

                                                <button onClick={toggleVisibility} aria-label="Toggle visibility">
                                                    <i className="fa-solid fa-eye"/>
                                                </button>
                                                {isInformationBlockVisible && (<textarea
                                                    className="form-control position-note-textarea"
                                                    id="position_note"
                                                    style={{width: "100%"}}
                                                    onChange={(e) => handleCommentSection(e.target.value)}
                                                    value={positionNote}
                                                />)}

                                            </Fragment>

                                        ) : <Fragment>
                                            <textarea
                                                className="form-control position-note-textarea"
                                                id="position_note"
                                                style={{width: "100%"}}
                                                onChange={(e) => handleCommentSection(e.target.value)}
                                                value={positionNote}
                                            />

                                        </Fragment>}


                                    </div>
                                    <div className={"position-component"}>
                                        <h3 style={{marginTop: 0}}>
                                            <i className="fa-solid  fa-bars-progress"
                                               style={{marginRight: "10px"}}></i>
                                            Autres projets en cours</h3>
                                        <OpenPositions id={position}/>
                                    </div>

                                </div>

                                <div className={"position-container right"}>
                                    <div className={"position-component"}>

                                        <div style={{textAlign: "left"}}>
                                            <h3><i className="fas fa-tasks" style={{marginRight: "10px"}}></i>
                                                Tâches</h3>
                                        </div>
                                        <ToggleContent tasks={tasks} headers={["Personne en charge"]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>

        ))}
    </Fragment>);
};

export default PositionDetail;
