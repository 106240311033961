import React from 'react';

const Wip = () => {
    return (
        <div>
            <div className={'wip'}>
                <span style={{fontSize: "3rem", marginRight: "20px"}}>🚧</span>
                En cours...
            </div>
        </div>
    );
};

export default Wip;