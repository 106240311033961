import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../config";
import { Link } from "react-router-dom";
import Table from "../components/Table";
import SearchBar from "../components/SearchBar";

const Clients = () => {
    const [customers, setCustomers] = useState([]);
    const [totalCustomers, setTotalCustomers] = useState([]);
    const [sortBy, setSortBy] = useState({ column: null, ascending: true });

    const columnMapping = {
        "Nom": "name_1",
        'Secteur(s)': 'branch_names',
        "Projets": "project_count",
        "Ville": "city",
        "Avis envoyé / donné": "avis"
    };

    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({ column: actualColumn, ascending });
    };

    const publishedArray = Array.isArray(customers) ? customers : [];
    const sortedArray = [...publishedArray].sort((a, b) => {
        if (sortBy.column === 'city') {
            return sortBy.ascending
                ? (a.city ? a.city.localeCompare(b.city || '') : 1)
                : (b.city ? b.city.localeCompare(a.city || '') : -1);
        } else if (sortBy.column === 'name_1') {
            return sortBy.ascending ? a.name_1.localeCompare(b.name_1) : b.name_1.localeCompare(a.name_1);
        } else if (sortBy.column === 'branch_names') {
            const branchNameA = a.branch_names;
            const branchNameB = b.branch_names;

            if (branchNameA === null || branchNameA === undefined) return sortBy.ascending ? 1 : -1;
            if (branchNameB === null || branchNameB === undefined) return sortBy.ascending ? -1 : 1;

            return sortBy.ascending
                ? branchNameA.localeCompare(branchNameB)
                : branchNameB.localeCompare(branchNameA);
        } else if (sortBy.column === 'project_count') {
            const projectCountA = a.project_count != null ? a.project_count : 0;
            const projectCountB = b.project_count != null ? b.project_count : 0;

            return sortBy.ascending ? projectCountA - projectCountB : projectCountB - projectCountA;
        } else if (sortBy.column === 'avis') {
            const avisCountA = a.avis != null ? a.avis : 0;
            const avisCountB = b.avis != null ? b.avis : 0;

            return sortBy.ascending ? avisCountA - avisCountB : avisCountB - avisCountA;
        }
        return 0; // Default case
    });

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_all_customers`)
            .then(res => {
                const filteredData = res.data.datas.filter(item => item.url !== '307.solution-digitale.ch' && item.url !== '308.solution-digitale.ch');
                const sortedData = filteredData.sort((a, b) => b.timestamp - a.timestamp);

                setTotalCustomers(sortedData);
                setCustomers(sortedData);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <div>
            <h2>Clients</h2>
            <SearchBar totalPositions={totalCustomers} setPublished={setCustomers} totalPositionsLength={customers} searchLabel={"clients trouvés"} />
            <Table
                handleSort={handleSort}
                sortBy={sortBy}
                hidePagination={false}
                data={sortedArray}
                headers={["Nom", "Secteur(s)", "Projets", "Ville", "Avis envoyé / donné"]}
                headersData={['customer_name', "branch_names", "project_count", "city", "avis"]}
            />
        </div>
    );
};

export default Clients;
