import React from 'react';
import '../assets/css/InfoBar.css';

const InfoBar = ({ data, type, closeInfoBar }) => {
    return (
        data !== "" && (
            <div className={`info-top-bar ${type}`}>
                <span className="cursor-pointer close-info" onClick={closeInfoBar}>
                    <i className="fa-sharp fa-solid fa-xmark" style={{ color: type }}></i>
                </span>
                <span style={{ flex: 3 }} dangerouslySetInnerHTML={{ __html: data }} />
            </div>
        )
    );
};

export default InfoBar;
