let API_BASE_URL, NODE_CHATGPT;
const OPENAI_API_KEY = 'sk-proj-8zFBmpYqJBceoq7LvedRT3BlbkFJt4kEBwTVpSZAw6bCUH2p';

if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    API_BASE_URL = 'http://localhost:4008'; // Update the port as needed
    NODE_CHATGPT = 'http://localhost:2000'; // Update the port as needed
} else {
    API_BASE_URL = 'https://x.solution-digitale.ch'; // Update with your actual production URL
    NODE_CHATGPT = 'https://x.solution-digitale.ch'; // Update with your actual production URL
}

export { API_BASE_URL, NODE_CHATGPT , OPENAI_API_KEY};
