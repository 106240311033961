import React from 'react';
import WIP from "../components/WIP";

const Produits = () => {
    return (
        <div>
            <h2>Produits</h2>
            <WIP/>
        </div>
    );
};

export default Produits;