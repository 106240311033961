import React, {useState} from 'react';

const ModalPlanification = ({openModal, closeModal, modalInfo, handleInputChange, handleKeyPress, setNewDepartment, departments, newDepartment, error, handleSave,handleAddDepartment}) => {

    return (
        <div>

            <div className={"modal-planification"} onClick={closeModal}>
                <div className={"modal-planification-container"} onClick={e => e.stopPropagation()}>
                    <h2>{modalInfo.title}</h2>
                    <input
                        autoFocus={true}
                        className={"input-trello-add"}
                        type="text"
                        name="name"
                        value={modalInfo.name}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Entrer un nom"
                    />
                    {modalInfo.type === 'task' && (
                        <>
                            <input
                                className={"input-trello-add"}
                                type="text"
                                name="description"
                                value={modalInfo.description}
                                onChange={handleInputChange}
                                placeholder="Entrer une description"
                            />
                            <input
                                className={"input-trello-add"}
                                type="text"
                                name="color"
                                value={modalInfo.color}
                                onChange={handleInputChange}
                                placeholder="Entrer une couleur"
                            />
                        </>
                    )}
                    <div className="department-select">
                        <select
                            name="department"
                            value={modalInfo.department}
                            onChange={handleInputChange}
                            className={"input-trello-add"}
                        >
                            <option value="">Sélectionner un département</option>
                            {departments.map(dept => (
                                <option key={dept.id} value={dept.name}>{dept.name}</option>
                            ))}
                        </select>
                        <button onClick={() => setNewDepartment('')} className="add-department-button">
                            <i className="fa-solid fa-plus"></i>
                        </button>
                    </div>
                    {newDepartment !== '' && (
                        <div>
                            <input
                                className={"input-trello-add"}
                                type="text"
                                value={newDepartment}
                                onChange={(e) => setNewDepartment(e.target.value)}
                                placeholder="Entrer un nouveau département"
                            />
                            <button onClick={handleAddDepartment}>Ajouter</button>
                        </div>
                    )}
                    {modalInfo.type === 'department' && (
                        <input
                            className={"input-trello-add"}
                            type="text"
                            name="department"
                            value={modalInfo.department}
                            onChange={handleInputChange}
                            placeholder="Entrer un département"
                        />
                    )}
                    {error && <span>{error}</span>}<br/>
                    <div className={"display-buttons-trello"}>
                        <button className={"cancel-trello"} onClick={closeModal}>Annuler</button>
                        <button className={"save-trello"} onClick={handleSave}>Ajouter</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalPlanification;