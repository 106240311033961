import React, {useState} from 'react';
import "../assets/css/DNSLookup.css";
import {useTable} from 'react-table';
import {saveAs} from 'file-saver';
import axios from 'axios';

const DnsLookup = () => {
    const [domain, setDomain] = useState('');
    const [results, setResults] = useState([]);

    const recordTypes = ['A', 'AAAA', 'CNAME', 'MX', 'NS', 'PTR', 'SOA', 'SRV', 'TXT'];

    const lookupDNS = (domain, type) => {
        const url = `https://dns.google/resolve?name=${domain}&type=${type}`;
        return axios.get(url).then(response => ({type, records: response.data.Answer || []}));
    };

    const handleCheckDNS = () => {
        const domainWithoutProtocol = domain.replace(/^https?:\/\//, '');

        const promises = [
            ...recordTypes.map(type => lookupDNS(domainWithoutProtocol, type)),
            ...recordTypes.map(type => lookupDNS(`www.${domainWithoutProtocol}`, type)) // Check www subdomain
        ];

        Promise.all(promises).then(results => {
            setResults(results);
        });
    };

    const handleExportDNS = () => {
        const now = new Date();
        now.setHours(now.getHours() + 2); // Add 2 hours to the current time
        const formattedDate = now.toISOString().replace('T', ' ').split('.')[0];
        const soaRecord = results.find(result => result.type === 'SOA');
        let soaLine = '';

        if (soaRecord && soaRecord.records.length > 0) {
            const soaData = soaRecord.records[0].data.split(' ');
            soaLine = `@        IN SOA  ${soaData[0]} ${soaData[1]} (${soaData.slice(2).join(' ')})`;
        }

        const dnsRecords = results.flatMap(result =>
            result.records.length > 0 && !(result.type === 'SOA' && result === soaRecord) // Exclude second SOA line
                ? result.records.map(record => {
                    const ttl = record.TTL || "Non spécifié"; // Default TTL to 300 if not specified
                    let name = "";
                    if (record.name.slice(0, -1) !== domain) {
                        name = 'www'
                    }
                    // Format TXT records with double quotes around the data
                    const data = result.type === 'TXT' ? `"${record.data}"` : record.data;

                    return `${name.padEnd(20)} ${ttl}  IN  ${result.type}  ${data}`;
                })
                : []
        );

        const header = `; Domain: ${domain}\n; Exported (y-m-d hh:mm:ss): ${formattedDate}\n; Actual version\n\n$TTL 3600\n${soaLine}`;

        const fileContent = [header, ...dnsRecords].join('\n');

        const blob = new Blob([fileContent], {type: 'text/plain;charset=utf-8'});
        saveAs(blob, `${domain}_dns_zone.txt`);
    };

    const columns = React.useMemo(
        () => [
            {Header: 'Type', accessor: 'type'},
            {Header: 'Name', accessor: 'name'},
            {Header: 'TTL', accessor: 'TTL'},
            {Header: 'Data', accessor: 'data'}
        ],
        []
    );

    const data = React.useMemo(() => {
        return results.flatMap(result =>
            result.records.length > 0
                ? result.records.map(record => ({
                    type: result.type,
                    name: record.name,
                    TTL: record.TTL,
                    data: record.data
                }))
                : [{
                    type: result.type,
                    name: '',
                    TTL: '',
                    data: 'Pas de donnée existante'
                }]
        );
    }, [results]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({columns, data});

    return (
        <div className="App">
            <div className="dns-container">
                <h2 style={{marginTop: "0"}} className={'title'}>DNS Lookup</h2>
                <div id={"search-container"}>
                    <input
                        autoFocus={true}
                        required={true}
                        type="text"
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        placeholder="URL par ex. https://solution-digitale.ch ou solution-digitale.ch"
                        className="search-form dns-search"
                    />
                </div>

                <div className={'display-flex gap-10 px-3 buttons-container-dns'}>
                    <button onClick={handleCheckDNS} className="bg-primary  flex-1">
                        <i className="fa-solid fa-magnifying-glass" style={{ fontSize: "20px", color: "white" }}></i>
                    </button>
                    {results.length > 0 &&
                        <>
                            <button onClick={handleExportDNS} className="export-button flex-1"><i className={'fa-regular fa-file'}/> Exporter la zone
                            </button>
                        </>
                    }
                </div>
                {results.length > 0 && (
                    <table style={{margin: "20px"}} {...getTableProps()} className="dns-table">
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="table-row">
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}
                                        className="table-header">{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className="table-body">
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="table-row">
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} className="table-cell">{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default DnsLookup;