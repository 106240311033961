import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../config";
import SearchBar from "../components/SearchBar";
import Table from "../components/Table";
import FilterForm from "../components/FilterForm";

const ListeToutesTaches = () => {
    const [tasks, setTasks] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [updatePositions, setUpdatePositions] = useState(0);
    const [sortBy, setSortBy] = useState({ column: null, ascending: true });
    const [filter, setFilter] = useState({ userId: '' });

    const columnMapping = {
        "Progression": "progress",
        'N° Bexio commande': 'order_id',
        'Responsable': 'username',
        "Titre de la commande": "title",
        "Tâche du jour": "finish_date",
        "Client": "name_1",
        "Date de création du projet": "date",
        // Add more mappings as needed
    };

    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({ column: actualColumn, ascending });
    };

    const handleAction = (action, id) => {
        if (action === 'edit') {
            const url = `/positions/${id}`;
            window.open(url);
        }

        if (action === 'delete') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette position ?");

            if (res) {
                axios.post(`${API_BASE_URL}/delete_position`, { id })
                    .then(response => {
                        console.log(response.data); // Handle server response if needed
                    })
                    .catch(error => {
                        console.error('Error deleting position:', error);
                    })
                    .finally(() => {
                        setUpdatePositions(updatePositions + 1);
                    });
            }
        }
    };

    const sortTasks = (tasks, sortBy) => {
        if (!sortBy.column) return tasks;

        const sortedTasks = [...tasks].sort((a, b) => {
            let aValue = a[sortBy.column];
            let bValue = b[sortBy.column];

            // Handle percentage sorting for 'progress'
            if (sortBy.column === 'progress') {
                aValue = parseFloat(aValue.replace('%', ''));
                bValue = parseFloat(bValue.replace('%', ''));
            }

            // Handle date sorting for 'finish_date'
            if (sortBy.column === 'finish_date') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }

            if (aValue < bValue) {
                return sortBy.ascending ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortBy.ascending ? 1 : -1;
            }
            return 0;
        });

        return sortedTasks;
    };

    const filterTasks = (tasks, filter) => {
        if (!filter.userId) return tasks;
        return tasks.filter(task => task.user_id.toString() === filter.userId);
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_absolutely_all_tasks`)
            .then(res => {
                console.log(res.data.datas);
                setTasks(res.data.datas);
                setTotalPositions(res.data.datas);
            });
    }, [updatePositions]);

    const sortedTasks = sortTasks(tasks, sortBy);
    const filteredTasks = filterTasks(sortedTasks, filter);

    return (
        <div>
            <SearchBar
                totalPositions={totalPositions}
                setPublished={setTasks}
                totalPositionsLength={tasks}
                positionLabel={'Liste de toutes les tâches en cours'}
                searchLabel={'tâches trouvées'}
            />
            <FilterForm
                filter={filter}
                setFilter={setFilter}
            />
            <Table
                typeOfPosition={"finished"}
                data={filteredTasks}
                handleSort={handleSort}
                sortBy={sortBy}
                showActions={true}
                handleAction={handleAction}
                headersData={['progress', 'order_id', 'username', 'title', 'name_1', 'finish_date']}
                headers={["Progression", "N° Bexio commande", "Responsable", "Titre de la commande", "Client", "Réalisation le", "Actions"]}
            />
        </div>
    )
};

export default ListeToutesTaches;
