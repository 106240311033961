import React, { useState, useRef } from 'react';

const SearchBar = ({ totalPositions, setPublished, totalPositionsLength, positionLabel, searchLabel }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const inputRef = useRef(null);

    const handleSearchChange = (event) => {
        const term = event.target.value;
        setSearchTerm(term);

        if (term.length >= 3) {
            const filtered = totalPositions.filter(item => (
                (item.url && item.url.toLowerCase().includes(term.toLowerCase())) ||
                (item.address && item.address.toLowerCase().includes(term.toLowerCase())) ||
                (item.name_1 && item.name_1.toLowerCase().includes(term.toLowerCase())) ||
                (item.branch_names && item.branch_names.toLowerCase().includes(term.toLowerCase())) ||
                (item.city && item.city.toLowerCase().includes(term.toLowerCase())) ||
                (item.version && item.version.toLowerCase().includes(term.toLowerCase())) ||
                (item.server && item.server.toLowerCase().includes(term.toLowerCase())) ||
                (item.username && item.username.toLowerCase().includes(term.toLowerCase())) ||
                (item.title && item.title.toLowerCase().includes(term.toLowerCase())) ||
                (item.order_id && item.order_id.toString().includes(term))
            ));
            setPublished(filtered);
        } else {
            setPublished(totalPositions);
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
        setPublished(totalPositions);
        inputRef.current.focus();
    };

    return (
        <div>
            <div className="search-bar">
                <h2><span className={'color-white'}>{positionLabel}</span></h2>
                <div className="p-15" style={{ position: 'relative' }}>
                    <input
                        id="search"
                        ref={inputRef}
                        className="form-control search-form"
                        type="text"
                        autoFocus={true}
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    {searchTerm && (
                        <button
                            onClick={clearSearch}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                background: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            &times;
                        </button>
                    )}
                </div>
                <p className={'positions-found'}>
                    {totalPositionsLength.length} {searchLabel}
                </p>
            </div>
        </div>
    );
};

export default SearchBar;
