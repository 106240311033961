import React, {useEffect, useState, Fragment} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import {Link, NavLink} from "react-router-dom";
import WIP from "./WIP";

const Table = ({
                   maxEntries,
                   typeOfPosition,
                   data,
                   headersData,
                   hidePagination,
                   handleSort,
                   sortBy,
                   headers,
                   showActions
               }) => {

    const [finished, setIsFinished] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(0);

    const MAX_ENTRIES_PAGINATION = maxEntries || 50;
    const MAX_DISPLAY_PAGES = 3; // Maximum number of pages to display in the pagination
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const [currentEntries, setCurrentEntries] = useState([]);
    const totalEntries = data.length;
    const totalPages = Math.ceil(totalEntries / entriesPerPage);

    console.log(data)
    useEffect(() => {
        setCurrentEntries(data.slice(indexOfFirstEntry, indexOfLastEntry))
    }, [data, indexOfFirstEntry, indexOfLastEntry]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Generate pagination buttons
    const pagesToShow = Math.min(MAX_DISPLAY_PAGES, totalPages);
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    const getColorFromProgress = (progress) => {
        let red, green;

        if (progress <= 50) {
            // Transition from red to yellow (50% progress)
            red = 255;
            green = Math.round(progress * 5.1);  // Green goes from 0 to 255
        } else {
            // Transition from yellow to green (50% to 100% progress)
            red = Math.round(255 - (progress - 50) * 5.1);  // Red goes from 255 to 0
            green = 255;
        }

        return `rgb(${red}, ${green}, 0)`;  // Blue stays at 0
    };

    const renderPages = () => {
        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(<button key={i} onClick={() => handlePageChange(i)}
                               className={currentPage === i ? 'active' : ''}>{i}</button>);
        }
        return pages;
    };

    const handleAction = (action, id) => {
        if (action === 'deletePermanently') {
            const res = window.confirm("Êtes-vous sûr de vouloir supprimer définitivement ce projet ? Cette action est irréversible.");
            if (res) {
                axios.post(`${API_BASE_URL}/archive_position_definitively`, {id})
                    .then(response => {
                        if (response.data.status === 'ok') {
                            setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting position:', error);
                    });
            }
        }
        if (action === 'edit') {
            const url = `/positions/${id}`;
            window.open(url);
        }
        if (action === 'finish') {
            axios.post(`${API_BASE_URL}/finish_position`, {id})
                .then(response => {
                    if (response.data.status === 'ok') {
                        setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                    }
                })
                .catch(error => {
                    console.error('Error deleting position:', error);
                });
        }
        if (action === 'restore') {
            axios.post(`${API_BASE_URL}/restore_position`, {id})
                .then(response => {
                    if (response.data.status === 'ok') {
                        setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                    }
                })
                .catch(error => {
                    console.error('Error deleting position:', error);
                });
        }
        if (action === 'delete') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette position ?");
            if (res) {
                axios.post(`${API_BASE_URL}/delete_position`, {id})
                    .then(response => {
                        if (response.data.status === 'ok') {
                            setCurrentEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
                        } else {
                            console.error('error');
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting position:', error);
                    });
            }
        }
    };

    useEffect(() => {
        if (hidePagination) {
            setEntriesPerPage(1000); // Adjust as needed
        } else {
            setEntriesPerPage(MAX_ENTRIES_PAGINATION);
        }
        if (typeOfPosition === 'finished') typeOfPosition = 'finished' ? setIsFinished(true) : setIsFinished(false);
    }, [typeOfPosition]);

    return (<Fragment>
        {/* Pagination */}
        {!hidePagination && (<div className="pagination">
            <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                &lt;&lt;
            </button>
            {currentPage > 1 && (<button onClick={() => handlePageChange(currentPage - 1)}>&lt;</button>)}
            {renderPages()}
            {currentPage < totalPages && (<button onClick={() => handlePageChange(currentPage + 1)}>&gt;</button>)}
            <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                &gt;&gt;
            </button>
        </div>)}
        {/* End pagination */}
        <div id="city-info" className="city-info hide-position-list"></div>

        <table className="table-search">
            <thead>
            <tr>
                {Array.isArray(headers) && headers.map(el => (
                    <th key={el} className="header-cell" onClick={() => handleSort(el)}>
                        {el} {sortBy && sortBy.column === el && (sortBy.ascending ?
                        <span className="center-block">▲</span> : <span className="center-block">▼</span>)}
                    </th>))}
            </tr>
            </thead>
            <tbody>
            {Array.isArray(currentEntries) && currentEntries.map(item => (<tr key={item.id}>
                {Array.isArray(headersData) && headersData.map(header => (
                    <td key={`${item.id}-${header}`} className="index-cell">
                        {header === 'date' ? <span>{item.date}</span> : header === 'url' ?
                            <NavLink to={'https://' + item.url} target="_blank">
                                {item.url}
                            </NavLink> : header === 'url_admin' ?
                                <NavLink to={'https://' + item.url + '/wp-admin'} target="_blank">
                                    <i className="fa-solid fa-user-shield"/>
                                </NavLink> : header === 'visu_url' ?
                                    <NavLink to={'https://visu.solution-digitale.ch/' + item.url}
                                             target="_blank">
                                                    <span className="text-left">
                                                        {item.url}
                                                    </span>
                                    </NavLink> : header === 'visu_url_admin' ? <NavLink
                                        to={'https://visu.solution-digitale.ch/' + item.url + '/wp-admin'}
                                        target="_blank">
                                        <i className="fa-solid fa-user-shield"/>
                                    </NavLink> : header === 'title' ?
                                        <NavLink className="text-left" to={'/positions/' + item.position_id}
                                                 target="_blank">
                                                        <span style={{color: "black", fontWeight: "600"}}
                                                              dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </NavLink> : header === 'progress' ? <span className="round-button"
                                                                                   style={{backgroundColor: getColorFromProgress(item[header])}}>
                                                                <p className="t-0 d-ib">{parseInt(item[header], 10)}%</p>
                                                            </span> : header === "id_customer" ?
                                            <NavLink className="text-left" to={'/positions/' + item.id}
                                                     target="_blank">
                                                                <span style={{color: "black", fontWeight: "600"}}
                                                                      dangerouslySetInnerHTML={{__html: item.id}}/>
                                            </NavLink> : header === "title_customer" ? <NavLink className="text-left"
                                                                                                to={'/positions/' + item.id}
                                                                                                target="_blank">
                                                <>
                                                    <div
                                                        className={"display-flex align-items-center gap-10"}>
                                                                 <span style={{color: "black", fontWeight: "600"}}
                                                                       dangerouslySetInnerHTML={{__html: item.title}}/>
                                                        {item.is_archived === 1 && <span style={{
                                                            borderRadius: "25px",
                                                            fontSize: ".8rem",
                                                            fontWeight: 800,
                                                            backgroundColor: "orangered",
                                                            color: "white",
                                                            padding: "5px 10px"
                                                        }}>Archivé</span>}
                                                    </div>

                                                </>
                                            </NavLink> : header === 'progress' ? <span className="round-button"
                                                                                       style={{backgroundColor: getColorFromProgress(item[header])}}>
                                                                <p className="t-0 d-ib">{parseInt(item[header], 10)}%</p>
                                                            </span> : header === 'Secteur' ? <span>
                                                                    <WIP/>
                                                                </span> : header === 'order_id' ? <span>
                                                                        <Link style={{
                                                                            display: "flex",
                                                                            gap: "5px",
                                                                            alignItems: "center",
                                                                            justifyContent: "space-evenly"
                                                                        }}
                                                                              to={`https://office.bexio.com/index.php/kb_order/show/id/${item['order_id']}`}
                                                                              target="_blank" rel="noreferrer"
                                                                              className="text-decoration-none">
                                                                            <i className="fa fa-external-link"
                                                                               aria-hidden="true" style={{
                                                                                fontSize: ".9rem",
                                                                                color: "var(--primary)"
                                                                            }}/>
                                                                            <img alt="Logo Bexio" height={20}
                                                                                 src={'https://x.solution-digitale.ch/assets/bexio-ag-logo-vector.png'}/>
                                                                            <span style={{
                                                                                fontSize: ".9rem",
                                                                                color: "var(--primary)",
                                                                                fontWeight: 700,
                                                                                fontStyle: "italic"
                                                                            }}>({item.order_id})</span>
                                                                        </Link>
                                                                    </span> : header === 'position_id' ? <span>
                                                                            <a href={`https://office.bexio.com/index.php/kb_order/show/id/${item['position_id']}`}
                                                                               target="_blank" rel="noreferrer">
                                                                                <p>{item[header]}
                                                                                    <i className="fa fa-external-link pl-10"
                                                                                       aria-hidden="true"/>
                                                                                </p>
                                                                            </a>
                                                                        </span> : header === 'project_count' || header === 'city' ?
                                                <span>
                                                                                <a href={`/client/${item['id']}`}
                                                                                   className="text-decoration-none text-left"
                                                                                   target="" rel="noreferrer">
                                                                                    <p>
                                                                                        {item[header]}
                                                                                    </p>
                                                                                </a>

                                                                            </span> : header === 'name_1' || header === 'name_2' ?
                                                    <span
                                                        id={item.id}
                                                        onMouseOver={(e) => {
                                                            const cityInfoDiv = document.getElementById('city-info');
                                                            cityInfoDiv.textContent = item.postcode + " " + item.city;

                                                            // Calculate the correct position every time the mouse hovers over the element
                                                            const rect = e.currentTarget.getBoundingClientRect();
                                                            const top = rect.bottom + window.scrollY;

                                                            // Set the position relative to the hovered element
                                                            cityInfoDiv.style.left = `50%`;
                                                            cityInfoDiv.style.top = `calc(${top}px - 40px)`;
                                                            cityInfoDiv.style.display = 'block';
                                                        }}
                                                        onMouseOut={() => {
                                                            // Hide the cityInfoDiv when the mouse is out
                                                            const cityInfoDiv = document.getElementById('city-info');
                                                            cityInfoDiv.style.display = 'none';
                                                        }}
                                                    >
    <a
        href={`/client/${item.contact_id}`}
        className="text-decoration-none text-left"
        target=""
        rel="noreferrer"
    >
      <p>{item[header]}</p>
    </a>
  </span> : header === 'branch_names' ? <span
                                                        className="info-branch">
                                                                                        {item[header]}
                                                                                    </span> : header === 'customer_name' ?
                                                        <span
                                                            className="info-branch">
                                                                                            <a href={`/client/${item['id']}`}
                                                                                               className="text-decoration-none text-left"
                                                                                               target=""
                                                                                               rel="noreferrer">
                                                                                                <p>
                                                                                                    {item['name_1']}
                                                                                                </p>
                                                                                            </a>
                                                                                        </span> : header === 'sended' ?
                                                            <span>
                                                                                                {item[header] === 'Envoyé' ?
                                                                                                    <p>Envoyée</p> : <p>
                                                                                                        <i className="fa-solid fa-triangle-exclamation"
                                                                                                           style={{color: "orangered"}}/>&nbsp;Pas
                                                                                                        envoyée
                                                                                                    </p>}
                                                                                            </span> : header === 'avis' ?
                                                                <span
                                                                    className={"text-center display-block"}>
                                                                                            {item['first_avis_sent_date'] !== null ?
                                                                                                <i className="fa-solid fa-check p-10"
                                                                                                   style={{color: "mediumseagreen"}}/> :
                                                                                                <i
                                                                                                    className="fa-solid fa-xmark p-10"
                                                                                                    style={{color: "orangered"}}/>}
                                                                    {item['avis'] !== 0 ?
                                                                        <i className="fa-solid fa-check p-10"
                                                                           style={{color: "mediumseagreen"}}/> : <i
                                                                            className="fa-solid fa-xmark p-10"
                                                                            style={{color: "orangered"}}/>}

                                                                                            </span> : header === 'order_start_date' || header === 'finish_date' || header === 'deletion_date' ?
                                                                    <span>
                                                                                                    {`${new Date(item[header]).getDate()}/${new Date(item[header]).getMonth() + 1}/${new Date(item[header]).getFullYear()}`}
                                                                                                </span> : data === 'position_id' ?
                                                                        <span
                                                                            onClick={() => handleAction('edit', item.position_id)}>
                                                                                                        <p dangerouslySetInnerHTML={{__html: item[header]}}></p>
                                                                                                    </span> : <span
                                                                            onClick={() => handleAction('edit', item.position_id)}>
                                                                                                        <p dangerouslySetInnerHTML={{__html: item[header]}}></p>
                                                                                                    </span>}
                    </td>))}
                {showActions && (<td className="td-cell">
                    <div className="position-icon-container">
                        {item.progress === '100' && !item.is_archived ? (
                            <i className="fa-solid position-icon fa-eye icon-eye"
                               onClick={() => handleAction('edit', item.position_id)}/>) : (!item.is_archived && (
                            <i className="fa-solid position-icon fa-check icon-check"
                               onClick={() => handleAction('finish', item.id)}/>))}
                        {localStorage.user_role === "Administrateur" && (<>
                            {item.is_archived ? (<>
                                <i style={{color: "yellowgreen", fontSize: "21px"}}
                                   className="cursor-pointer position-icon fa-solid position-icon fa-trash-restore icon-trash-restore"
                                   onClick={() => handleAction('restore', item.id)}></i>
                                <i className="fa-solid position-icon fa-trash icon-trash"
                                   onClick={() => handleAction('deletePermanently', item.id)}></i>
                            </>) : (<i className="fa-solid position-icon fa-trash icon-trash"
                                       onClick={() => handleAction('delete', item.id)}></i>)}
                        </>)}
                    </div>
                </td>)}
            </tr>))}
            </tbody>
        </table>
        {!hidePagination && (<div className="pagination" style={{borderRadius: "0 0 5px 5px"}}>
            <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                &lt;&lt;
            </button>
            {currentPage > 1 && (<button onClick={() => handlePageChange(currentPage - 1)}>&lt;</button>)}
            {renderPages()}
            {currentPage < totalPages && (<button onClick={() => handlePageChange(currentPage + 1)}>&gt;</button>)}
            <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                &gt;&gt;
            </button>
        </div>)}
        {/* End pagination */}
    </Fragment>);
};

export default Table;
