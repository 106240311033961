import React, { createContext, useState } from 'react';


// Create a context for managing authentication state
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const login = () => {
        // Perform login logic here, set isLoggedIn to true upon successful login
        setIsLoggedIn(true);
    };

    const logout = () => {
        // Perform logout logic here, set isLoggedIn to false upon logout
        setIsLoggedIn(false);
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
