import React, {useEffect, useState, Fragment} from 'react';
import axios from 'axios';
import Slider from 'rc-slider'; // Import the Slider component from rc-slider
import 'rc-slider/assets/index.css';
import {API_BASE_URL} from "../config";
import {Link} from "react-router-dom";
import Table from "./Table";

const ToggleContent = ({tasks, headers}) => {

    const [showCompleted, setShowCompleted] = useState(false);
    const [showOngoing, setShowOngoing] = useState(true);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [pendingTasks, setPendingTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [timeSpent, setTimeSpent] = useState()
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [tasksTimes, setTasksTimes] = useState(pendingTasks);
    const [durationLighting, setDurationLighting] = useState(false)
    const [sortBy, setSortBy] = useState({column: null, ascending: true});
    const handleCheckboxChange = (taskId) => {
        if (selectedTasks.includes(taskId)) {
            setSelectedTasks(selectedTasks.filter(id => id !== taskId));
        } else {
            setSelectedTasks([...selectedTasks, taskId]);
        }
    };

    const handleFinishDate = (tasksId, newDate) => {
        const updatedTaskIds = Array.isArray(tasksId) ? tasksId : [tasksId]; // Ensure taskIds is an array
        console.log(newDate)
        updatedTaskIds.forEach(taskId => {

            axios.post(`${API_BASE_URL}/update_task_finish_date`, {time: newDate, id_task: taskId})
                .then(response => {
                    // reload la page pour afficher la nouvelle date...
                    //window.location.reload()

                    setPendingTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                        ...task, start_date: newDate
                    } : task))
                });
        })
    };

    const formatDateForInput = (date, formatForDisplay = false) => {
        if (!date) {
            return formatForDisplay ? 'jj/mm/aaaa hh:mm' : 'jj/mm/aaaa';  // Return placeholder based on context
        }

        const d = new Date(date);
        if (isNaN(d.getTime())) {
            return formatForDisplay ? 'jj/mm/aaaa hh:mm' : 'jj/mm/aaaa';  // Return placeholder for invalid date
        }

        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        const hours = d.getHours().toString().padStart(2, '0');
        const minutes = d.getMinutes().toString().padStart(2, '0');

        if (formatForDisplay) {
            return `${day}/${month}/${year} ${hours}:${minutes}`;  // For display (dd/mm/yyyy hh:mm)
        } else {
            return `${year}-${month}-${day}T${hours}:${minutes}`;  // For datetime-local input (yyyy-mm-ddThh:mm)
        }
    };


    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allTaskIds = pendingTasks.map(task => task.id);
            setSelectedTasks(allTaskIds);
        } else {
            setSelectedTasks([]);
        }
    };


    const handleUserChange = (tasksId, userId) => {
        const updatedTaskIds = Array.isArray(tasksId) ? tasksId : [tasksId]; // Ensure tasksId is an array

        updatedTaskIds.forEach(taskId => {
            // Optimistically update the UI
            setPendingTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                ...task, user_id: userId
            } : task));

            axios.post(`${API_BASE_URL}/update_user_task`, {
                user_id: userId, task_id: taskId, admin_id: localStorage.user_id
            })
                .then((res) => {
                    if (res.status !== 200) { // Revert the optimistic update if the API call fails
                        setPendingTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                            ...task, user_id: null // Assuming `null` reverts the assignment
                        } : task));
                    }
                })
                .catch(error => {
                    console.error('Error updating user task:', error);
                    // Revert the optimistic update if the API call fails
                    setPendingTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                        ...task, user_id: null // Assuming `null` reverts the assignment
                    } : task));
                });
        });
    };


    const handleProgressChange = (taskIds, value) => {
        console.log(value)
        const updatedTaskIds = Array.isArray(taskIds) ? taskIds : [taskIds]; // Ensure taskIds is an array

        updatedTaskIds.forEach(taskId => {
            // Assuming you have an API endpoint to update task progress
            axios.post(`${API_BASE_URL}/update_task_progress`, {
                taskId, progress: value,
            }).then(response => {
                console.log(response.data.message)
                const {data} = response;
                if (data.status === 'ok') {
                    // Update the task progress in the UI
                    setPendingTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                        ...task, progress: value
                    } : task));
                } else {
                    console.error('Error updating task progress:', data.message);
                }
            }).catch(error => {
                setDurationLighting(true)
                console.error('Error updating task progress:', error);
            })
        })
    }

    const handleTimeSpent = (user, time, id) => {
        setTimeSpent(time)
        axios.post(`${API_BASE_URL}/update_time_spent`, {user, time, id_task: id})
            .then(response => {

                setPendingTasks(prevTasks => prevTasks.map(task => task.id === id ? {
                    ...task, time_spent: time
                } : task))
            })
            .catch(error => console.error('Error updating time spent:', error))
    }

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_users`)
            .then(res => {
                const {data} = res;
                if (data && data.length > 0) {
                    const userNamesArray = data.map(user => user); // Extract usernames from each user
                    setUsers(userNamesArray); // Set the usernames in state

                    if (tasks && Array.isArray(tasks)) {
                        // Map through tasks to format the finished_at field using the same function
                        const formattedTasks = tasks.map(task => ({
                            ...task,
                            start_date: formatDateForInput(task.start_date),  // Format for input
                            finished_at: formatDateForInput(task.finished_at, true)  // Format for display
                        }));

                        // Split tasks into completed and pending tasks
                        const completed = formattedTasks.filter(task => task.progress === '100');
                        const pending = formattedTasks.filter(task => task.progress !== '100');

                        setCompletedTasks(completed);
                        setPendingTasks(pending);
                    }
                } else {
                    console.error('No user data received');
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    }, [tasks]); // Include tasks in the dependency array

// Reset durationLighting after displaying the alert
    useEffect(() => {
        if (durationLighting) {
            alert('La durée ne doit pas être vide.');
            setDurationLighting(false); // Reset the state after showing the alert
        }
    }, [durationLighting]);

    return (<div className="">
        <div style={{marginBottom: "10px!important"}}>
            {/* Toggle container for ongoing tasks */}
            <div className="toggle-container">
                <div className="toggle-button" onClick={() => setShowOngoing(prev => !prev)}
                     style={{marginBottom: "10px!important"}}>
                    Tâches en cours
                </div>
                <div className={'show'}>
                    {/* Hidden content for ongoing tasks */}

                    <div className="selected-tasks-options">
                        <button className={'button-action shadow mt-10'} style={{backgroundColor: '#F6F8FA'}}
                                onClick={() => handleProgressChange(selectedTasks, 100)}>
                            Mettre à 100%
                        </button>
                        {localStorage.user_role === 'Administrateur' && (<Fragment>
                                <span className={'button-action shadow mt-10'} style={{backgroundColor: '#F6F8FA'}}>
                                    Assigner à...
                                </span>
                            <select className={'select-assign'} onChange={(e) => {
                                handleUserChange(selectedTasks, e.target.value);
                            }}
                            >
                                {users.map((user) => (<option
                                    value={user.id}
                                    name={user.username}
                                >
                                    {user.username}
                                </option>))}
                            </select>
                            <button className={'button-action shadow mt-10'}
                                    style={{backgroundColor: '#F6F8FA'}}>Date de réalisation
                            </button>
                            <input type={"date"} onChange={(e) => handleFinishDate(selectedTasks, e.target.value)}/>

                        </Fragment>)}

                    </div>
                    <table className="task-table">
                        <thead>
                        <tr>
                            <th>
                                <input type="checkbox" onChange={handleSelectAll}/>
                            </th>
                            <th>Progression</th>
                            <th>Nom de la tâche</th>
                            <th>Date de réalisation</th>
                            <th style={{minWidth: "150px"}}>Durée</th>
                            { // On check si y a des params custom
                                headers && (<Fragment>
                                    {headers.map((el) => <th>{el}</th>)}
                                </Fragment>)}
                        </tr>
                        </thead>
                        <tbody>
                        {pendingTasks.map((task) => (<tr>
                            <td
                                style={{textAlign: "center", cursor: "pointer"}}
                                onClick={(e) => {
                                    if (e.target.tagName !== 'INPUT') {
                                        handleCheckboxChange(task.id);
                                    }
                                }}
                            >
                                {(localStorage.user_name === task.username || localStorage.user_role === 'Administrateur') && (
                                    <input
                                        type="checkbox"
                                        checked={selectedTasks.includes(task.id)}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => handleCheckboxChange(task.id)}
                                    />)}
                            </td>
                            <td className="progress-td">
                                {((parseInt(localStorage.user_id)) === parseInt(task.user_id) || localStorage.user_role === 'Administrateur') && (
                                    <span>
                                        <Slider
                                            min={0}
                                            max={100}
                                            defaultValue={task.progress}
                                            onAfterChange={value => handleProgressChange(task.id, value)}
                                        />
                                    </span>)}
                                <span style={{
                                    display: "flex", justifyContent: "center", marginTop: "10px"
                                }}>
                                    {task.progress}%
                                </span>
                            </td>
                            <td>{task.title}</td>
                            <td>
                                <div className={'input-time-container'}>
                                    {(localStorage.user_role === "Administrateur") ? (
                                        <input
                                            className={'input-time'}
                                            type={"datetime-local"}
                                            onChange={(e) => handleFinishDate(task.id, e.target.value)}
                                            value={formatDateForInput(task.start_date)}  // Adjusted for datetime-local format
                                        />
                                    ) : (
                                        <span className='input-time display-flex justify-content-center'>
                {formatDateForInput(task.start_date)}
            </span>
                                    )}
                                </div>
                            </td>


                            <td>
                                <div className={'input-time-container'}>
                                    {((parseInt(localStorage.user_id)) === parseInt(task.user_id) || localStorage.user_role === 'Administrateur') && (
                                        <input
                                            className={'input-time'}
                                            style={{padding: "10px 5px", height: "40px"}}
                                            type={'time'}
                                            onChange={(e) => handleTimeSpent(task.user_id, e.target.value, task.id)}
                                            onBlur={(e) => handleTimeSpent(task.user_id, e.target.value, task.id)} // Reload when input loses focus
                                            value={task.time_spent}
                                        />)}
                                </div>
                            </td>
                            {headers && <Fragment>

                                {headers.map((el) => (el === 'Actions' ? (<td>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <Link to={`/positions/${task.position_id}`}>
                                            <i
                                                className="fa-solid position-icon fa-eye"
                                                style={{
                                                    color: "blue",
                                                    fontSize: "1.25rem",
                                                    cursor: "pointer",
                                                    textAlign: "center"
                                                }}
                                            />
                                        </Link>
                                    </div>
                                </td>) : el === 'Client' ? (<td>{task.name_1}</td>) : el === 'Personne en charge' ? (
                                    <td>
                                        {localStorage.user_role ? (<select
                                            style={{
                                                padding: "10px 5px", fontFamily: "Montserrat"
                                            }}
                                            onChange={(e) => {
                                                handleUserChange(task.id, e.target.value);
                                            }}
                                            value={task.user_id} // This ensures the select box shows the correct user
                                        >
                                            {users.map((user) => (<option
                                                value={user.id}
                                                name={user.username}
                                            >
                                                {user.username}
                                            </option>))}
                                        </select>) : (<div>
                                            {task.username}
                                        </div>)}
                                    </td>) : null))}
                            </Fragment>}
                        </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Toggle container for completed tasks */}
            <div className="toggle-container">
                <div className="toggle-button" onClick={() => setShowCompleted(prev => !prev)}>
                    Tâches terminées
                </div>
                <div className={`hidden-content ${showCompleted ? 'show' : ''}`}>
                    <Table
                        typeOfPosition={"finished"}
                        data={completedTasks}
                        hidePagination={true}
                        sortBy={sortBy}
                        headers={["Nom de la tâche", "Terminé le", "Personne en charge", "Durée"]}
                        headersData={['title', 'finished_at', 'username', 'time_spent']}
                    />
                </div>
            </div>

        </div>
    </div>);
};

export default ToggleContent;