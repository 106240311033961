import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../config";

const FilterForm = ({ filter, setFilter }) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_users`)
            .then(res => {
                setUsers(res.data);
            });
    }, []);

    const handleChange = (e) => {
        setFilter({ ...filter, userId: e.target.value });
    };

    return (
        <div className={"search-bar mt-20"} >
            <div style={{ display: "flex", alignItems: "flex-end" }}>
                <span style={{ marginBottom: "10px", paddingRight: "10px", flex: 1 }}>Responsable<br/></span>
                <select
                    style={{ padding: "10px 5px 10px 5px", borderRadius: "5px", fontFamily: "Montserrat", flex: 2 }}
                    value={filter.userId}
                    onChange={handleChange}
                >
                    <option value="">Tous</option>
                    {users.map(user => (
                        <option key={user.id} value={user.id.toString()}>
                            {user.username}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default FilterForm;
