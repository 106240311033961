import React, {Fragment, useState} from 'react';
import axios from 'axios';
import {API_BASE_URL, OPENAI_API_KEY} from "../config";
import Loading from "../components/Loading";
import SEOResult from "../components/SEOResult";

const Seo = () => {
    const [input, setInput] = useState('');

    const [loadingTitles, setLoadingTitles] = useState(false)
    const [loadingKeywords, setLoadingKeywords] = useState(false)
    const [loadingDescriptions, setLoadingDescriptions] = useState(false)

    const [hasSearched, setHasSearched] = useState(false)

    const [metaTitle1, setMetaTitle1] = useState('');
    const [metaTitle2, setMetaTitle2] = useState('');
    const [metaTitle3, setMetaTitle3] = useState('');

    const [metaDescription1, setMetaDescription1] = useState('');
    const [metaDescription2, setMetaDescription2] = useState('');
    const [metaDescription3, setMetaDescription3] = useState('');

    const [metaKeywords, setMetaKeywords] = useState('');
    const handleChange = (e) => {
        setInput(e.target.value);
    };

    const handleClick = () => {

        setLoadingTitles(true)
        setLoadingDescriptions(true)
        setLoadingKeywords(true)
        setHasSearched(true)
        axios.post(`${API_BASE_URL}/get_url_texts`, {url: input})
            .then(response => {
                // Filter out empty strings and join the non-empty strings with newline
                const textContent = response.data.textContent.filter(str => str.trim() !== '').join('\n');
                const limitedTextContent = textContent.slice(0, 700);

                // Récupération Meta description
                axios.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-4',
                    messages: [{
                        role: 'user',
                        content: 'Peux-tu me donner trois descriptions meta HTML différentes et en français qui résument le contenu de ce site? Tu ne dois PAS inclure de balise HTML (title par exemple). Tu dois impérativement respecter ce format : 1. (Le premier titre). 2. (Deuxième). 3. (troisième).  Tu dois mettre une majuscule au début de chaque mot et au nom de la marque (mais pas ailleurs). Tu ne dois pas inclure de parenthèse, ni de guillemets (sauf si c\'est nécessaire dans un mot de liaison).' + limitedTextContent
                    }]
                }, {
                    headers: {
                        'Authorization': `Bearer ${OPENAI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                })

                    .then(response => {
                        setLoadingDescriptions(false)
                        // Parse the response and extract meta titles
                        const responseData = response.data;
                        if (responseData && responseData.choices && responseData.choices.length > 0) {
                            const metaDescriptions = responseData.choices.map(choice => choice.message.content);

                            // Extract meta titles based on occurrence of "1.", "2.", "3."
                            const desc1StartIndex = metaDescriptions[0].indexOf("1.");
                            const desc2StartIndex = metaDescriptions[0].indexOf("2.");
                            const desc3StartIndex = metaDescriptions[0].indexOf("3.");

                            // Update state with the extracted meta titles
                            setMetaDescription1(metaDescriptions[0].substring(desc1StartIndex + 2, desc2StartIndex).trim());
                            setMetaDescription2(metaDescriptions[0].substring(desc2StartIndex + 2, desc3StartIndex).trim());
                            setMetaDescription3(metaDescriptions[0].substring(desc3StartIndex + 2).trim());
                        }
                    })

                // Make a POST request to ChatGPT API with the joined text content
                axios.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-4',
                    messages: [{
                        role: 'user',
                        content: 'Peux-tu me donner trois meta titles HTML différents et en français qui résument le contenu de ce site? Tu ne dois PAS inclure de balise HTML (title par exemple). Tu dois impérativement respecter ce format : 1. Le premier titre. 2. Deuxième. 3. troisième. Tu dois mettre une majuscule au début de chaque mot et au nom de la marque (mais pas ailleurs). Tu ne dois pas inclure de parenthèse, ni de guillemets (sauf si c\'est nécessaire dans un mot de liaison).' + limitedTextContent
                    }]
                }, {
                    headers: {
                        'Authorization': `Bearer ${OPENAI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                })

                    .then(response => {
                        setLoadingTitles(false)
                        // Parse the response and extract meta titles
                        const responseData = response.data;
                        if (responseData && responseData.choices && responseData.choices.length > 0) {
                            const metaTitles = responseData.choices.map(choice => choice.message.content);

                            // Extract meta titles based on occurrence of "1.", "2.", "3."
                            const title1StartIndex = metaTitles[0].indexOf("1.");
                            const title2StartIndex = metaTitles[0].indexOf("2.");
                            const title3StartIndex = metaTitles[0].indexOf("3.");

                            // Update state with the extracted meta titles
                            setMetaTitle1(metaTitles[0].substring(title1StartIndex + 2, title2StartIndex).trim());
                            setMetaTitle2(metaTitles[0].substring(title2StartIndex + 2, title3StartIndex).trim());
                            setMetaTitle3(metaTitles[0].substring(title3StartIndex + 2).trim());
                        }


                    })
                // Make a POST request to ChatGPT API with the joined text content
                axios.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-4',
                    messages: [{
                        role: 'user',
                        content: 'Peux-tu me donner 15 mots clé pertinents en français qui résument le contenu de ce site? Tu ne dois PAS inclure de balise HTML (title par exemple). Tu dois impérativement séparer chacun des mots clé par une virgule suivie d\'un espace.  Tu dois mettre une majuscule au début de chaque mot et au nom de la marque (mais pas ailleurs). Tu ne dois pas inclure de parenthèse, ni de guillemets (sauf si c\'est nécessaire dans un mot de liaison).' + limitedTextContent
                    }]
                }, {
                    headers: {
                        'Authorization': `Bearer ${OPENAI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                })

                    .then(response => {
                        setLoadingKeywords(false)
                        // Parse the response and extract meta titles
                        const responseData = response.data;
                        if (responseData && responseData.choices && responseData.choices.length > 0) {

                            setHasSearched(true)

                            console.log(responseData)

                            // Update state with the extracted meta titles
                            setMetaKeywords(responseData.choices[0].message.content);
                        }


                    })
                    .catch(error => {
                        console.error('Error making ChatGPT API request:', error);
                    });
            })
            .catch(error => {
                console.error('Error fetching URL text content:', error);
            });

    };

    return (
        <div>
            <h2>Meta Generator</h2>
            <div className={'seo-search-container search-bar display-flex'}>
                <label >
                    <input autoFocus placeholder={'Entrez l\'URL du site, par exemple : https://example.com/'}
                           className={'input search-bar-seo'} type="url" value={input} onChange={handleChange} />
                </label>
                <button className={'btn-generate-meta'} style={{cursor: "pointer", flex: 1}}
                        onClick={handleClick}>Générer les métas
                </button>
            </div>

            {
                hasSearched && (
                    <Fragment>
                        <div className={'mb-10'}>
                            <h3>Titres meta</h3>
                            {loadingTitles && <Loading/>}
                            <SEOResult data={metaTitle1}/>
                            <SEOResult data={metaTitle2}/>
                            <SEOResult data={metaTitle3}/>
                        </div>
                        <div className={'mt-10'}>
                            <h3>Descriptions meta</h3>
                            {loadingDescriptions && <Loading/>}
                            <SEOResult data={metaDescription1}/>
                            <SEOResult data={metaDescription2}/>
                            <SEOResult data={metaDescription3}/>

                        </div>
                        <div className={'mt-10'}>
                            <h3>Keywords</h3>
                            {loadingKeywords && <Loading/>}
                            <SEOResult data={metaKeywords}/>
                        </div>
                    </Fragment>

                )
            }

        </div>
    )
};

export default Seo;
