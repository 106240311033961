import React from 'react';
import WIP from "../components/WIP";

const CreationTaches = () => {
    return (
        <div>
            <h2>Création des tâches par défaut</h2>
            <WIP />
        </div>
    );
};

export default CreationTaches;