import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import ToggleContent from "../components/ToggleContent";


const ListeTaches = () => {

    const [userTasks, setUserTasks] = useState([])

    useEffect(() => {

        axios.post(`${API_BASE_URL}/get_all_tasks_by_user`, {user_id: localStorage.user_id})
            .then(res => {
                setUserTasks(res.data.datas)
                console.log(res.data.datas)
            })
    },[])

    return (
        <div>
            <h2>Mes tâches</h2>

            <div className={'position-component'}>
                <ToggleContent tasks={userTasks}
                               headers={["Client", "Actions"]}
                               headersData={userTasks.username}
                />
            </div>

        </div>
    );
};

export default ListeTaches;