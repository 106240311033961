import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import 'c3/c3.css';
import c3 from 'c3';
import '../assets/css/Productivity.css'; // Import CSS for styling
import {API_BASE_URL} from "../config";
import SearchBar from "../components/SearchDropdown";
import SearchDropdown from "../components/SearchDropdown";
import WIP from "../components/WIP";

const Productivity = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [data, setData] = useState([]);
    const chartRef = useRef(null);
    const [error, setError] = useState("Merci de sélectionner un projet");

    // Fetch the list of projects
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_projects_distinct`)
            .then(response => {
                setProjects(response.data);
                console.log(response.message);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
                setError('Error fetching projects');
            });
    }, []);

    // Fetch productivity data when a project is selected
    useEffect(() => {
        if (selectedProject) {
            console.log(selectedProject);
            axios.post(`${API_BASE_URL}/get_productivity_positions`, {articleCode: selectedProject})
                .then(response => {
                    console.log("Data fetched:", response.data); // Log the response data
                    setData(response.data);
                    setError(null)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setError('Error fetching data');
                });
        }
    }, [selectedProject]);

    useEffect(() => {
        if (chartRef.current) {
            if (data.length > 0) {
                // Map month strings to French month names
                const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

                // Initialize array to store total hours for each month
                const totalHours = new Array(12).fill(0); // 12 months

                // Convert time_spent to hours and aggregate by month
                data.forEach(item => {
                    const month = parseInt(item.task_finish_month, 10) - 1; // Convert month string to index (0-based)
                    const [hours, minutes, seconds] = item.time_spent.split(':').map(Number);
                    const totalHoursForTask = hours + minutes / 60 + seconds / 3600; // Convert to hours

                    if (month >= 0 && month < 12) {
                        totalHours[month] += totalHoursForTask; // Sum hours for the corresponding month
                    }
                });

                // Prepare chart data for C3
                const chartData = {
                    columns: [['Heures totales', ...totalHours]], // y-axis data
                    categories: frenchMonths // x-axis labels
                };

                // Generate the chart
                chartRef.current.__chart = c3.generate({
                    bindto: chartRef.current, data: {
                        columns: chartData.columns, type: 'bar'
                    }, axis: {
                        x: {
                            type: 'category', categories: chartData.categories, tick: {
                                rotate: -55, // Rotate labels 55 degrees
                                multiline: false
                            }, height: 130 // Increase height of x-axis for better label visibility
                        }, y: {
                            label: {
                                text: 'Heures totales', position: 'outer-middle'
                            }
                        }
                    }, tooltip: {
                        grouped: false // Disable grouping tooltip
                    }, bar: {
                        width: {
                            ratio: 0.8 // Adjust the bar width
                        }
                    }
                });
            } else {
                // Clear the chart when there is no data
                chartRef.current.__chart = c3.generate({
                    bindto: chartRef.current, data: {
                        columns: [['Heures totales', 0]], // Pass empty data
                        type: 'bar'
                    }, axis: {
                        x: {
                            type: 'category', categories: [], // No categories
                        }, y: {
                            label: {
                                text: 'Heures totales', position: 'outer-middle'
                            }
                        }
                    }
                });
            }
        }
    }, [data, chartRef, selectedProject]);


    // Handle selection of a project
    const handleSelectProject = (project) => {
        setSelectedProject(project); // Set the selected project
    };

    return (<div>
        <h2>Productivité</h2>
        <div>
            <div className={"container chart-container p-10 mb-20"}>
                <div className={"display-flex gap-10"}>
                    <div className={"search-bar container flex-1 m-0"}>

                        <SearchDropdown data={projects} onSelect={handleSelectProject} placeholder="solutionWEB PRO - Création..." />

                    </div>


                    <div className={"search-bar container flex-1 m-0 flex-direction-row display-flex select-project"}>
                        <select
                            className={"input flex-1 sea"}
                            id="project-select"
                            onChange={(e) => {
                                const selectedInternCode = projects.find(project => project.id === parseInt(e.target.value))?.intern_name;
                                setSelectedProject(selectedInternCode);
                            }}
                        >
                            <option value="">-- Sélectionner un projet --</option>
                            {projects.map(project => (<option key={project.id} value={project.id}>
                                {project.intern_code + " " + project.intern_name}
                            </option>))}
                        </select>
                    </div>
                </div>
            </div>
        </div>


        <div className={"chart-container"}>
            {error && <p>{error}</p>}
            {selectedProject && <p>{selectedProject}</p>}
            <div className={""} style={{height: "500px"}} ref={chartRef}/>

        </div>

        <div className={"chart-container mt-20"}>
            <h2 className={"text-left"}><i className={"fa-solid fa-chart-line"}/> Moyennes </h2>
            <WIP />
            <div className={"display-flex"}>
            </div>
        </div>
    </div>);
};

export default Productivity;
