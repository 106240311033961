import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import {Link} from "react-router-dom";

const LastTasks = ({number}) => {
    const [lastTasks, setLastTasks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}/get_last_user_tasks/`, {id: localStorage.user_id, number: number})
            .then(res => {
                // Assuming res.data is an array of tasks
                setLastTasks(res.data.data);
                setLoading(false);
                console.log(res.data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [number]); // Include 'number' in the dependency array

    return (<div className={'last-tasks-container'}>
        {loading ? (<p>Chargement...</p>) : lastTasks.length > 0 ? (<div style={{textAlign: "left"}}>
            {lastTasks.map(task => (<ul key={task.id}>
                <Link className={'text-decoration-none'} to={'liste-taches'}>
                    <p className={'p-5'}>
                        {task.title} (<span className="font-bold">{task.name_1}</span>)
                    </p>
                </Link>
            </ul>))}
        </div>) : (<h4>Félicitations, vous êtes à jour ! <br/> <img height={100} style={{marginTop: "20px"}}
                                                                    src={'https://x.solution-digitale.ch/assets/fiesta.png'} alt={'Fini!'}/>
        </h4>)}
    </div>);
};

export default LastTasks;
