import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../config";
import ReportingChart from "../components/ReportingChart";
import WIP from "../components/WIP";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/css/Reporting.css';
import '../assets/css/ReportingGrid.css';
import { fr } from 'date-fns/locale';
import ReportingCard from "../components/ReportingCard";

registerLocale('fr', fr);

const Reporting = () => {
    const [dailyAvg, setDailyAvg] = useState(0);
    const [totalAmount, setTotalAmount] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [durationPositions, setDurationPositions] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('last30Days'); // Default filter
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isCustom, setIsCustom] = useState(false);

    useEffect(() => {
        if (selectedFilter !== 'custom') {
            fetchData(selectedFilter); // Fetch data based on selected filter
        }
    }, [selectedFilter]);

    useEffect(() => {
        if (isCustom) {
            fetchData('custom', startDate, endDate); // Fetch data for custom duration
        }
    }, [startDate, endDate]);

    const fetchData = (filter, customStartDate, customEndDate) => {
        let startDate;
        const endDate = customEndDate || new Date(); // End date is either custom end date or today

        if (filter === 'custom') {
            startDate = customStartDate;
        } else {
            // Calculate start date based on the selected filter
            switch (filter) {
                case 'lastYear':
                    startDate = new Date(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDate());
                    break;
                case 'last6Months':
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 5, endDate.getDate());
                    break;
                case 'last3Months':
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 2, endDate.getDate());
                    break;
                case 'last60Days':
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 59);
                    break;
                case 'last30Days':
                default:
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate());
                    break;
            }
        }

        // Format the start and end dates for the API call
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        axios.post(`${API_BASE_URL}/get_total_amount_for_finished_positions`, {
            startDate: formattedStartDate,
            endDate: formattedEndDate
        })
            .then(response => {
                console.log(response.data); // Log the response data
                setDurationPositions(response.data);

                // Calculate the total amount
                const totalAmount = response.data.reduce((acc, item) => acc + item.position_total, 0);
                setTotalAmount(totalAmount);

                // Calculate the number of days between startDate and endDate
                const startDate = new Date(formattedStartDate);
                const endDate = new Date(formattedEndDate);
                const timeDifference = endDate.getTime() - startDate.getTime();
                const numberOfDays = timeDifference / (1000 * 3600 * 24) + 1; // +1 to include both start and end date

                // Calculate the daily average
                const dailyAvg = (totalAmount / numberOfDays).toFixed(2);
                setDailyAvg(dailyAvg + ' CHF');

                // Set the total positions
                setTotalPositions(response.data.length);
            })
            .catch(error => {
                console.error('Error:', error); // Log any errors
                // Handle the error condition if needed
            });

    };

    const formatDate = (date) => {
        return date.toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
    };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setIsCustom(filter === 'custom');
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setSelectedFilter('custom');
            setIsCustom(true);
        }
    };

    return (
        <div>
            <h2>Vue de la production</h2>
            <div className={'display-flex'} style={{justifyContent: "flex-end"}}>
            </div>
            <div className="parent">
                <div className="div1">
                    <ReportingCard header={"revenus"} data={`${totalAmount.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`} icon={'fa-sack-dollar'}/>
                </div>
                <div className="div2">
                    <ReportingCard header={"projets terminés"} data={totalPositions} icon={'fa-flag-checkered'}/>
                </div>
                <div className="div3">
                    <ReportingCard header={"moyenne quotidienne"} data={`${dailyAvg.toLocaleString('en-CH', {minimumFractionDigits: 2})}`} icon={'fa-calendar-check'}/>
                </div>
                <div className="div4">
                    <ReportingChart data={durationPositions} selectedFilter={selectedFilter} customStartDate={startDate} customEndDate={endDate}/>
                </div>
                <div className={'div5'} style={{flexDirection: "column"}}>
                    <select value={selectedFilter} onChange={(e) => handleFilterChange(e.target.value)} style={{
                        padding: '8px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        backgroundColor: '#f9f9f9',
                        fontSize: '16px',
                        width: '242px',
                        outline: 'none',
                    }}>
                        <option value="last30Days">30 derniers jours</option>
                        <option value="last60Days">60 derniers jours</option>
                        <option value="last3Months">3 derniers mois</option>
                        <option value="last6Months">6 derniers mois</option>
                        <option value="lastYear">Dernière année</option>
                        <option value="custom">Durée personnalisée</option>
                    </select><br/>
                    {isCustom && (
                        <div>
                            <DatePicker
                                locale="fr"
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                calendarStartDay={1} // Set Monday as the first day of the week
                            />
                        </div>
                    )}
                </div>
                <div className="div6"><ReportingCard header={""}/></div>
            </div>
        </div>
    );
};

export default Reporting;
