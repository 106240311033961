import {useDrag} from "react-dnd";
import moment from "moment/moment";
import React, {Fragment} from "react";

const TaskCard = ({task, onClick}) => {
    console.log(task)
    const [{isDragging}, drag] = useDrag({
        type: 'task',
        item: task,
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    return (
        <Fragment>
            <div
                className="task-card"
                onClick={() => onClick(task)}
                ref={drag}
                style={{opacity: isDragging ? 0.5 : 1}}
            >
                <h4>{task.title}</h4>
                <p>{moment(task.end).format('LLL')}</p>
            </div>
        </Fragment>
    );
};

export default TaskCard ;