import React, { useState } from 'react';

const SearchDropdown = ({ data, onSelect, placeholder = "Rechercher..." }) => {
    const [searchTerm, setSearchTerm] = useState(''); // Search term input by the user
    const [filteredData, setFilteredData] = useState(data); // Filtered data for dropdown

    console.log(data)
    console.log(searchTerm)
    // Handle search input change
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value) {
            const filtered = data.filter(item =>
                item.intern_code.toLowerCase().includes(value.toLowerCase()) || // Search by 'intern_code'
                item.intern_name.toLowerCase().includes(value.toLowerCase())  // Search by 'intern_code'
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(data); // Reset to full list if search term is empty
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
    };

    // Handle item selection
    const handleSelectItem = (item) => {
        setSearchTerm(item);
        setFilteredData([]);
        onSelect(item);
    };

    return (
        <div className="search-dropdown">
            <div className="" style={{ position: 'relative' }}>

            <input
                className={"input"}
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={placeholder}
                autoComplete="off"
            />
            {searchTerm && (
                <button
                    onClick={clearSearch}
                    style={{
                        position: 'absolute',
                        right: '0px',
                        top: '20px',
                        transform: 'translateY(-50%)',
                        background: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '24px',
                    }}
                >
                    &times;
                </button>
            )}
            {/* Display dropdown if search term is not empty and filteredData has items */}
            {searchTerm && filteredData.length > 0 && (
                <ul className="dropdown-search">
                    {filteredData.map((item) => (
                        <li key={item.id} onClick={() => handleSelectItem(item.intern_name)}>
                            {item.intern_name} {/* Display intern_code in the dropdown */}
                        </li>
                    ))}
                </ul>
            )}


        </div>
        </div>
    );
};

export default SearchDropdown;
