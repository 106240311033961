// CustomAddCardLink.js
import React, {Fragment} from 'react';
import './CustomReactTrello.css'

const CustomAddCardLink = ({ onClick }) => (
    <Fragment >
<div className={"add-new-card"} style={{backgroundColor: "red"}}>
    <span onClick={onClick}><i className="fa-solid fa-plus mr-10" style={{fontSize: ".9rem"}}></i>Ajouter une nouvelle carte</span>

</div>
    </Fragment>
);

export default CustomAddCardLink;
