import React, { useEffect, useRef, useState } from 'react';
import c3 from 'c3';
import 'c3/c3.css';

const ReportingChart = ({ data, selectedFilter, customStartDate, customEndDate }) => {
    const chartRef = useRef(null);
    const [tickCount, setTickCount] = useState(0);

    useEffect(() => {
        if (data && data.length > 0) {
            const processedData = processData(data, selectedFilter);
            console.log("Processed Data:", processedData); // Log processed data
            createChart(processedData);
        }
    }, [data, selectedFilter, customStartDate, customEndDate]);

    const processData = (data, selectedFilter) => {
        const dailySums = {};
        const dateRange = getDateRange(selectedFilter); // Get the complete date range based on the filter

        dateRange.forEach(date => {
            dailySums[date] = 0;
        });

        data.forEach(item => {
            const date = new Date(item.end_date);
            const formattedDate = formatDate(date);
            const sum = parseFloat(item.position_total);

            dailySums[formattedDate] += sum;
        });

        return dailySums;
    };

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDateRange = (filter) => {
        const endDate = customEndDate || new Date();
        let startDate;
        let count = 0;

        if (filter === 'last30Days') {
            startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 30);
            count = 30;
        } else if (filter === 'last60Days') {
            startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 59);
            count = 30;
        } else if (filter === 'last3Months') {
            startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 89);
            count = 90;
        } else if (filter === 'last6Months') {
            startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 179);
            count = 180;
        } else if (filter === 'lastYear') {
            startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 365);
            count = 365;
        } else if (filter === 'custom' && customStartDate && customEndDate) {
            startDate = new Date(customStartDate);
            count = Math.ceil((customEndDate - customStartDate) / (1000 * 60 * 60 * 24)) + 1;
        }

        const dateRange = [];
        let currentDate = new Date(startDate);

        if (filter !== 'custom') {
            endDate.setDate(endDate.getDate());
        }

        while (currentDate <= endDate) {
            dateRange.push(formatDate(new Date(currentDate)));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        setTickCount(count);

        return dateRange;
    };

    const createChart = (dailySums) => {
        const dates = Object.keys(dailySums);
        const amounts = Object.values(dailySums);

        console.log("Dates:", dates); // Log dates
        console.log("Amounts:", amounts); // Log amounts

        c3.generate({
            bindto: chartRef.current,
            data: {
                x: 'x',
                columns: [['x', ...dates], ['Montant', ...amounts]],
                type: 'area-spline'
            },
            axis: {
                x: {
                    type: 'category',
                    tick: {
                        format: (x) => {
                            const roundedIndex = Math.round(x); // Round the index to the nearest integer
                            if (roundedIndex >= 0 && roundedIndex < dates.length) {
                                const date = new Date(dates[roundedIndex]);
                                if (!isNaN(date)) {
                                    const day = date.getDate().toString().padStart(2, '0');
                                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                                    return `${day}/${month}`;
                                } else {
                                    console.error(`Invalid date at index ${roundedIndex}: ${dates[roundedIndex]}`);
                                    return 'Invalid Date';
                                }
                            } else {
                                console.error(`Index out of bounds: ${roundedIndex}`);
                                return 'Invalid Date';
                            }
                        },
                        multiline: false,
                        outer: false,
                        count: Math.min(dates.length, 15), // Adjust the number of ticks to display
                    }
                }
            },
            point: {
                show: true
            }
        });
    };


    return <div ref={chartRef}></div>;
};

export default ReportingChart;
