import React from 'react';
import WIP from "../components/WIP";

const Commandes = () => {
    return (
        <div>

            <h2>Commandes en cours</h2>
            <WIP/>
        </div>
    );
};

export default Commandes;