import React from 'react';
import WIP from "../components/WIP";

const PatchXgrid = () => {
    return (
        <div>
            <h2>Patch XGrid</h2>
            <WIP/>
        </div>
    );
};

export default PatchXgrid;